/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { User } from './user';
import { ProducerUrl } from './producerUrl';
import { Country } from './country';
import { Image } from './image';


export interface Producer { 
    about?: string;
    contactAddress?: string;
    contactCity?: string;
    contactEmail?: string;
    contactPhone?: string;
    contactPostcode?: string;
    contactWhatsapp?: string;
    country?: Country;
    created?: string;
    description?: string;
    facebookHandler?: string;
    gender?: Producer.GenderEnum;
    headerProfileImage?: Image;
    id?: number;
    instagramHandler?: string;
    isFeatured?: boolean;
    isMadeByVerified?: boolean;
    lastUpdate?: string;
    memberOf?: Producer.MemberOfEnum;
    middleProfileImage?: Image;
    name?: string;
    parentOrganisation?: string;
    permalink?: string;
    personalMessage?: string;
    personalMessageSignature?: string;
    previewCode: string;
    producerLinks: Array<ProducerUrl>;
    seoDescription?: string;
    shortDescription?: string;
    status?: Producer.StatusEnum;
    subBrand?: string;
    syncDefaultCurrency?: string;
    syncLastUpdate?: string;
    syncOptions?: string;
    syncType?: Producer.SyncTypeEnum;
    syncWebsiteUrl?: string;
    synced?: boolean;
    thumbnailProfileImage?: Image;
    twitterHandler?: string;
    type?: Producer.TypeEnum;
    user?: User;
    videoId?: string;
    where?: string;
}
export namespace Producer {
    export type GenderEnum = 'Female' | 'Male' | 'NotApplicable' | 'Other';
    export const GenderEnum = {
        Female: 'Female' as GenderEnum,
        Male: 'Male' as GenderEnum,
        NotApplicable: 'NotApplicable' as GenderEnum,
        Other: 'Other' as GenderEnum
    };
    export type MemberOfEnum = 'Company' | 'Cooperative' | 'Group' | 'None';
    export const MemberOfEnum = {
        Company: 'Company' as MemberOfEnum,
        Cooperative: 'Cooperative' as MemberOfEnum,
        Group: 'Group' as MemberOfEnum,
        None: 'None' as MemberOfEnum
    };
    export type StatusEnum = 'Archived' | 'Deleted' | 'Draft' | 'Hidden' | 'Published';
    export const StatusEnum = {
        Archived: 'Archived' as StatusEnum,
        Deleted: 'Deleted' as StatusEnum,
        Draft: 'Draft' as StatusEnum,
        Hidden: 'Hidden' as StatusEnum,
        Published: 'Published' as StatusEnum
    };
    export type SyncTypeEnum = 'None' | 'Shopify' | 'ShopifyWithTagFilter';
    export const SyncTypeEnum = {
        None: 'None' as SyncTypeEnum,
        Shopify: 'Shopify' as SyncTypeEnum,
        ShopifyWithTagFilter: 'ShopifyWithTagFilter' as SyncTypeEnum
    };
    export type TypeEnum = 'Artisan' | 'Brand' | 'Other';
    export const TypeEnum = {
        Artisan: 'Artisan' as TypeEnum,
        Brand: 'Brand' as TypeEnum,
        Other: 'Other' as TypeEnum
    };
}


