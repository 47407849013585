/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface TrackingEventDto {
  eventType: TrackingEventDto.EventTypeEnum;
  from: string;
  identifier?: string;
  to?: string;
  userId?: number;
}
export namespace TrackingEventDto {
  export type EventTypeEnum = 'BrandToProductClick' | 'BuyNewClickProducer' | 'BuyNewClickProduct' | 'BuySecondHandClickProducer' | 'BuySecondHandClickProduct' | 'ContactClick' | 'MendClickProducer' | 'MendClickProduct' | 'PageView' | 'ProducerClaim' | 'ProducerSearch' | 'ProducerView' | 'ProductClaim' | 'ProductSearch' | 'ProductView' | 'RentClickProducer' | 'RentClickProduct';
  export const EventTypeEnum = {
    BrandToProductClick: 'BrandToProductClick' as EventTypeEnum,
    BuyNewClickProducer: 'BuyNewClickProducer' as EventTypeEnum,
    BuyNewClickProduct: 'BuyNewClickProduct' as EventTypeEnum,
    BuySecondHandClickProducer: 'BuySecondHandClickProducer' as EventTypeEnum,
    BuySecondHandClickProduct: 'BuySecondHandClickProduct' as EventTypeEnum,
    ContactClick: 'ContactClick' as EventTypeEnum,
    MendClickProducer: 'MendClickProducer' as EventTypeEnum,
    MendClickProduct: 'MendClickProduct' as EventTypeEnum,
    PageView: 'PageView' as EventTypeEnum,
    ProducerClaim: 'ProducerClaim' as EventTypeEnum,
    ProducerSearch: 'ProducerSearch' as EventTypeEnum,
    ProducerView: 'ProducerView' as EventTypeEnum,
    ProductClaim: 'ProductClaim' as EventTypeEnum,
    ProductSearch: 'ProductSearch' as EventTypeEnum,
    ProductView: 'ProductView' as EventTypeEnum,
    RentClickProducer: 'RentClickProducer' as EventTypeEnum,
    RentClickProduct: 'RentClickProduct' as EventTypeEnum
  };
}


