/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface IArticleListView { 
    id?: number;
    isFeatured?: boolean;
    name?: string;
    permalink?: string;
    published?: string;
    shortDescription?: string;
    type?: IArticleListView.TypeEnum;
    videoId?: string;
}
export namespace IArticleListView {
    export type TypeEnum = 'Craft' | 'Material' | 'News' | 'Tutorial';
    export const TypeEnum = {
        Craft: 'Craft' as TypeEnum,
        Material: 'Material' as TypeEnum,
        News: 'News' as TypeEnum,
        Tutorial: 'Tutorial' as TypeEnum
    };
}


