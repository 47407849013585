/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserRoleList } from './userRoleList';
import { Image } from './image';


export interface LoggedUserList { 
    email?: string;
    firstname?: string;
    id?: number;
    lastname?: string;
    organisationId?: number;
    phone?: string;
    profileImage?: Image;
    roles: Array<UserRoleList>;
    status?: LoggedUserList.StatusEnum;
    timezone?: string;
}
export namespace LoggedUserList {
    export type StatusEnum = 'Active' | 'Cancelled';
    export const StatusEnum = {
        Active: 'Active' as StatusEnum,
        Cancelled: 'Cancelled' as StatusEnum
    };
}


