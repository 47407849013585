/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { GrantedAuthority } from './grantedAuthority';
import { Image } from './image';


export interface User { 
    accountNonLocked: boolean;
    authorities: Array<GrantedAuthority>;
    authoritiesStore: Array<GrantedAuthority>;
    createdOn: string;
    credentialsNonExpired: boolean;
    email?: string;
    enabled: boolean;
    firstname?: string;
    id?: number;
    lastSeenOn?: string;
    lastUpdateOn: string;
    lastname?: string;
    organisationId?: number;
    phone?: string;
    profileImage?: Image;
    resetPasswordExpiryDate?: string;
    resetPasswordToken?: string;
    status?: User.StatusEnum;
    timezone?: string;
    username: string;
}
export namespace User {
    export type StatusEnum = 'Active' | 'Cancelled';
    export const StatusEnum = {
        Active: 'Active' as StatusEnum,
        Cancelled: 'Cancelled' as StatusEnum
    };
}


