import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {FourOFourComponent} from './four-ofour/four-ofour.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'page',
    loadChildren: () => import('./page/page.module').then(m => m.PageModule)
  },
  {
    path: 'edu',
    loadChildren: () => import('./edu/edu.module').then(m => m.EduModule)
  },
  {
    path: 'products',
    loadChildren: () => import('./products/products.module').then(m => m.ProductsModule)
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'meet',
    loadChildren: () => import('./artisans-brands/artisans-brands.module').then(m => m.ArtisansBrandsModule)
  },
  {
    path: 'artisans',
    loadChildren: () => import('./brands/brands.module').then(m => m.BrandsModule)
  },
  {
    path: 'brands',
    loadChildren: () => import('./brands/brands.module').then(m => m.BrandsModule)
  },
  {
    path: 'collections',
    loadChildren: () => import('./collections/collections.module').then(m => m.CollectionsModule)
  },
  {path: '404', component: FourOFourComponent},
  {path: '**', redirectTo: '404', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
