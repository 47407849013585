/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AddEditCertificationReqBody { 
    auditType?: string;
    certificationType: AddEditCertificationReqBody.CertificationTypeEnum;
    description?: string;
    duration?: string;
    name: string;
    ordering?: number;
    permalink?: string;
}
export namespace AddEditCertificationReqBody {
    export type CertificationTypeEnum = 'Certification' | 'Membership';
    export const CertificationTypeEnum = {
        Certification: 'Certification' as CertificationTypeEnum,
        Membership: 'Membership' as CertificationTypeEnum
    };
}


