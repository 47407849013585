/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IValueCategoryListView } from './iValueCategoryListView';


export interface IProducerValueListView { 
    id?: number;
    status?: IProducerValueListView.StatusEnum;
    value?: IValueCategoryListView;
}
export namespace IProducerValueListView {
    export type StatusEnum = 'NotVerified' | 'Verified';
    export const StatusEnum = {
        NotVerified: 'NotVerified' as StatusEnum,
        Verified: 'Verified' as StatusEnum
    };
}


