import { Component, OnInit } from '@angular/core';
import {MetadataService} from '../shared/services/metadata.service';

@Component({
  selector: 'app-four-ofour',
  templateUrl: './four-ofour.component.html',
  styleUrls: ['./four-ofour.component.scss']
})
export class FourOFourComponent implements OnInit {

  constructor(private metadataService: MetadataService) { }

  ngOnInit(): void {
    this.metadataService.setMetadata('Not Found');
  }

}
