/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface IProducerMiniListView { 
    id?: number;
    name?: string;
    permalink?: string;
    type?: IProducerMiniListView.TypeEnum;
}
export namespace IProducerMiniListView {
    export type TypeEnum = 'Artisan' | 'Brand' | 'Other';
    export const TypeEnum = {
        Artisan: 'Artisan' as TypeEnum,
        Brand: 'Brand' as TypeEnum,
        Other: 'Other' as TypeEnum
    };
}


