/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Widgetobject { 
    acceptedParams: Array<Widgetobject.AcceptedParamsEnum>;
    dashboard: string;
    description: string;
    name: string;
    order: number;
    widgetType: Widgetobject.WidgetTypeEnum;
}
export namespace Widgetobject {
    export type AcceptedParamsEnum = 'NumberOfTopItems' | 'ProducerId' | 'TimeFrame' | 'VendorId';
    export const AcceptedParamsEnum = {
        NumberOfTopItems: 'NumberOfTopItems' as AcceptedParamsEnum,
        ProducerId: 'ProducerId' as AcceptedParamsEnum,
        TimeFrame: 'TimeFrame' as AcceptedParamsEnum,
        VendorId: 'VendorId' as AcceptedParamsEnum
    };
    export type WidgetTypeEnum = 'Graph' | 'PagedList' | 'SingleValueTiled';
    export const WidgetTypeEnum = {
        Graph: 'Graph' as WidgetTypeEnum,
        PagedList: 'PagedList' as WidgetTypeEnum,
        SingleValueTiled: 'SingleValueTiled' as WidgetTypeEnum
    };
}


