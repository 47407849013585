/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AddEditCoreMaterialInProduct { 
    coreMaterialFor: AddEditCoreMaterialInProduct.CoreMaterialForEnum;
    coreMaterialId?: number;
    percent?: number;
    productId?: number;
}
export namespace AddEditCoreMaterialInProduct {
    export type CoreMaterialForEnum = 'Component' | 'Finish' | 'Trim' | 'Yarn';
    export const CoreMaterialForEnum = {
        Component: 'Component' as CoreMaterialForEnum,
        Finish: 'Finish' as CoreMaterialForEnum,
        Trim: 'Trim' as CoreMaterialForEnum,
        Yarn: 'Yarn' as CoreMaterialForEnum
    };
}


