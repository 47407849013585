/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IProducerMiniListView } from './iProducerMiniListView';


export interface ProductListView {
    artisan?: IProducerMiniListView;
    brand?: IProducerMiniListView;
    condition?: ProductListView.ConditionEnum;
    created?: string;
    currency?: string;
    id?: number;
    isFeatured?: boolean;
    isFeaturedInCollections?: boolean;
    isMadeByVerified?: boolean;
    name?: string;
    ordering?: number;
    permalink?: string;
    price?: number;
    shortDescription?: string;
    type?: ProductListView.TypeEnum;
}
export namespace ProductListView {
    export type ConditionEnum = 'New' | 'Rental' | 'SecondHand' | 'Mend';
    export const ConditionEnum = {
        New: 'New' as ConditionEnum,
        Rental: 'Rental' as ConditionEnum,
        SecondHand: 'SecondHand' as ConditionEnum,
    Mend: 'Mend' as ConditionEnum
    };
    export type TypeEnum = 'Part' | 'Whole';
    export const TypeEnum = {
        Part: 'Part' as TypeEnum,
        Whole: 'Whole' as TypeEnum
    };
}


