/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AddEditProducerReqBody { 
    about?: string;
    contactAddress?: string;
    contactCity?: string;
    contactEmail?: string;
    contactPhone?: string;
    contactPostcode?: string;
    contactWhatsapp?: string;
    countryId?: number;
    description?: string;
    facebookHandler?: string;
    gender?: AddEditProducerReqBody.GenderEnum;
    instagramHandler?: string;
    isFeatured?: boolean;
    isMadeByVerified?: boolean;
    memberOf: AddEditProducerReqBody.MemberOfEnum;
    name: string;
    parentOrganisation?: string;
    permalink?: string;
    personalMessage?: string;
    personalMessageSignature?: string;
    seoDescription?: string;
    shortDescription?: string;
    status?: AddEditProducerReqBody.StatusEnum;
    subBrand?: string;
    syncDefaultCurrency?: string;
    syncOptions?: string;
    syncType?: AddEditProducerReqBody.SyncTypeEnum;
    syncWebsiteUrl?: string;
    synced?: boolean;
    twitterHandler?: string;
    type: AddEditProducerReqBody.TypeEnum;
    videoId?: string;
    where?: string;
}
export namespace AddEditProducerReqBody {
    export type GenderEnum = 'Female' | 'Male' | 'NotApplicable' | 'Other';
    export const GenderEnum = {
        Female: 'Female' as GenderEnum,
        Male: 'Male' as GenderEnum,
        NotApplicable: 'NotApplicable' as GenderEnum,
        Other: 'Other' as GenderEnum
    };
    export type MemberOfEnum = 'Company' | 'Cooperative' | 'Group' | 'None';
    export const MemberOfEnum = {
        Company: 'Company' as MemberOfEnum,
        Cooperative: 'Cooperative' as MemberOfEnum,
        Group: 'Group' as MemberOfEnum,
        None: 'None' as MemberOfEnum
    };
    export type StatusEnum = 'Archived' | 'Deleted' | 'Draft' | 'Hidden' | 'Published';
    export const StatusEnum = {
        Archived: 'Archived' as StatusEnum,
        Deleted: 'Deleted' as StatusEnum,
        Draft: 'Draft' as StatusEnum,
        Hidden: 'Hidden' as StatusEnum,
        Published: 'Published' as StatusEnum
    };
    export type SyncTypeEnum = 'None' | 'Shopify' | 'ShopifyWithTagFilter';
    export const SyncTypeEnum = {
        None: 'None' as SyncTypeEnum,
        Shopify: 'Shopify' as SyncTypeEnum,
        ShopifyWithTagFilter: 'ShopifyWithTagFilter' as SyncTypeEnum
    };
    export type TypeEnum = 'Artisan' | 'Brand' | 'Other';
    export const TypeEnum = {
        Artisan: 'Artisan' as TypeEnum,
        Brand: 'Brand' as TypeEnum,
        Other: 'Other' as TypeEnum
    };
}


