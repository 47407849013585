import {LocalStorageService} from 'ngx-webstorage';

export abstract class LocallyStoredService {

  constructor(private ls: LocalStorageService) {
  }

  protected abstract getPrefix(): string;

  protected isCacheValid(): boolean {
    /*const lastUpdateDateJson = this.ls.retrieve(this.getLastUpdateKey());
    if (lastUpdateDateJson) {
      const lastUpdateDate = JSON.parse(lastUpdateDateJson);
      const now = (new Date()).valueOf();

    }
    return false;*/
    return !(!this.ls.retrieve(this.getDataKey()));
  }

  getFromCache(): any {
    return this.ls.retrieve(this.getDataKey());
  }

  saveToCache(data: any): any {
    return this.ls.store(this.getDataKey(), data);
  }

  /*private getLastUpdateKey(): string {
    return this.getPrefix() + '-last-update';
  }*/

  private getDataKey(): string {
    return this.getPrefix() + '-data';
  }

}
