<hr class="g-brd-gray-light-v4 my-0">
<!--<script id="CookieDeclaration" src="https://consent.cookiebot.com/6357397c-3b0b-48ad-a0a0-cd5d2fba4034/cd.js" type="text/javascript" async></script>-->
<!-- Footer -->
<div class="footer-background">
  <footer class="container g-pt-60 g-pb-60">
  <div class="row justify-content-end">
    <div class="col-lg-3 g-mb-30">
      <a class="d-inline-block mb-4" href="#">
        <img class="g-width-100 g-height-auto" src="/assets/madeby/footer_tagline_white.svg" alt="Love the origin">
      </a>

      <p class="g-color-white g-font-size-13 mb-0">2022 &copy; All Rights Reserved.</p>
    </div>

    <div class="col-sm-4 col-lg-2 ml-auto g-mb-30">
      <!-- Links -->
      <ul class="list-unstyled g-color-white g-font-size-13 mb-0" *ngIf="showNavigation">
        <li class="my-2"><i class="mr-2 fa fa-angle-right"></i>
          <a class="u-link-v5 g-color-white g-color-primary--hover" [routerLink]="['/auth']">Brand Access</a>
        </li>
        <li class="my-2"><i class="mr-2 fa fa-angle-right"></i>
          <a class="u-link-v5 g-color-white g-color-primary--hover" [routerLink]="['/meet']">Artisans and Brands</a>
        </li>
        <li class="my-2"><i class="mr-2 fa fa-angle-right"></i>
          <a class="u-link-v5 g-color-white g-color-primary--hover" [routerLink]="['/products']">Discover Products</a>
        </li>
        <li class="my-2"><i class="mr-2 fa fa-angle-right"></i>
          <a class="u-link-v5 g-color-white g-color-primary--hover" [routerLink]="['/edu']">Blog</a>
        </li>
      </ul>
      <!-- End Links -->
    </div>

    <div class="col-sm-4 col-lg-2 g-mb-30">
      <!-- Links -->
      <ul class="list-unstyled g-color-white g-font-size-13 mb-0" *ngIf="showNavigation">
        <li class="my-2"><i class="mr-2 fa fa-angle-right"></i>
          <a class="u-link-v5 g-color-white g-color-primary--hover" [routerLink]="['/page/about']">About</a>
        </li>
        <li class="my-2"><i class="mr-2 fa fa-angle-right"></i>
          <a class="u-link-v5 g-color-white g-color-primary--hover" [routerLink]="['/page/acceptable-use-policy']">Acceptable Use Policy </a>
        </li>
        <li class="my-2"><i class="mr-2 fa fa-angle-right"></i>
          <a class="u-link-v5 g-color-white g-color-primary--hover" [routerLink]="['/page/terms-and-conditions']">Terms & Conditions</a>
        </li>
        <li class="my-2"><i class="mr-2 fa fa-angle-right"></i>
          <a class="u-link-v5 g-color-white g-color-primary--hover" [routerLink]="['/page/privacy-policy']">Privacy &amp; Policy</a>
        </li>
      </ul>
      <!-- End Links -->
    </div>

    <div class="col-sm-4 col-lg-2 g-mb-30">
      <h3 class="h6 g-color-black g-font-weight-600 text-uppercase mb-4">Follow Us</h3>

      <!-- Social Icons -->
      <ul class="list-inline mb-0">
        <li class="list-inline-item g-mx-2">
          <a class="u-icon-v1 u-icon-size--sm u-icon-slide-up--hover g-color-white mby-border-color-white rounded-circle" href="https://www.instagram.com/madeby.earth/" target="_blank" aria-label="Visit Instagram account">
            <i class="g-font-size-default g-line-height-1 u-icon__elem-regular fa fa-instagram"></i>
            <i class="g-font-size-default g-line-height-0_8 u-icon__elem-hover fa fa-instagram"></i>
          </a>
        </li>
        <li class="list-inline-item g-mx-2">
          <a class="u-icon-v1 u-icon-size--sm u-icon-slide-up--hover g-color-white mby-border-color-white rounded-circle" href="https://www.facebook.com/Discover.Madeby" target="_blank" aria-label="Visit Facebook account">
            <i class="g-font-size-default g-line-height-1 u-icon__elem-regular fa fa-facebook"></i>
            <i class="g-font-size-default g-line-height-0_8 u-icon__elem-hover fa fa-facebook"></i>
          </a>
        </li>
        <li class="list-inline-item g-mx-2">
          <a class="u-icon-v1 u-icon-size--sm u-icon-slide-up--hover g-color-white mby-border-color-white rounded-circle" href="https://twitter.com/Madeby_Earth" target="_blank" aria-label="Visit Twitter account">
            <i class="g-font-size-default g-line-height-1 u-icon__elem-regular fa fa-twitter"></i>
            <i class="g-font-size-default g-line-height-0_8 u-icon__elem-hover fa fa-twitter"></i>
          </a>
        </li>
        <li class="list-inline-item g-mx-2">
          <a class="u-icon-v1 u-icon-size--sm u-icon-slide-up--hover g-color-white mby-border-color-white rounded-circle" href="https://www.linkedin.com/company/discover-madeby/" target="_blank" aria-label="Visit LinkedIn account">
            <i class="g-font-size-default g-line-height-1 u-icon__elem-regular fa fa-linkedin"></i>
            <i class="g-font-size-default g-line-height-0_8 u-icon__elem-hover fa fa-linkedin"></i>
          </a>
        </li>
      </ul>
      <!-- End Social Icons -->
    </div>
  </div>
</footer>
</div>
<!-- End Footer -->
