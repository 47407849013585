/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {IProducerMiniListView} from './iProducerMiniListView';
import {IProductCategoryListView} from './iProductCategoryListView';
import {IProducerValueListView} from './iProducerValueListView';


export interface ProductWithCategoriesListView {
  artisan?: IProducerMiniListView;
  brand?: IProducerMiniListView;
  categories?: Array<IProductCategoryListView>;
  condition?: ProductWithCategoriesListView.ConditionEnum;
  created?: string;
  currency?: string;
  id?: number;
  isFeatured?: boolean;
  isFeaturedInCollections?: boolean;
  isMadeByVerified?: boolean;
  name?: string;
  ordering?: number;
  permalink?: string;
  price?: number;
  shortDescription?: string;
  type?: ProductWithCategoriesListView.TypeEnum;
  values?: Array<any>;
  linkTypes?: string[];
  materials?: any[];
}

export namespace ProductWithCategoriesListView {
  export type ConditionEnum = 'New' | 'Rental' | 'SecondHand' | 'Mend';
  export const ConditionEnum = {
    New: 'New' as ConditionEnum,
    Rental: 'Rental' as ConditionEnum,
    SecondHand: 'SecondHand' as ConditionEnum,
    Mend: 'Mend' as ConditionEnum
  };
  export type TypeEnum = 'Part' | 'Whole';
  export const TypeEnum = {
    Part: 'Part' as TypeEnum,
    Whole: 'Whole' as TypeEnum
  };
}


