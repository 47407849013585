/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Producer } from './producer';
import { CoreMaterialUseInProduct } from './coreMaterialUseInProduct';
import { BrickData } from './brickData';
import { Country } from './country';
import { Image } from './image';
import { ProductInkType } from './productInkType';
import { ProductUrl } from './productUrl';


export interface Product {
    ageGroup?: Product.AgeGroupEnum;
    artisan?: Producer;
    bodyFabricType?: Product.BodyFabricTypeEnum;
    brand?: Producer;
    brickData?: BrickData;
    categorizationStandard?: Product.CategorizationStandardEnum;
    circularProductDataProtocolVersion?: string;
    parentOrganization?: string;
    subBrand?: string;
    colorCategory?: Product.ColorCategoryEnum;
    componentsUsed?: Array<CoreMaterialUseInProduct>;
    condition?: Product.ConditionEnum;
    created?: string;
    currency?: string;
    dataCarrierLocation?: string;
    dataCarrierMaterial?: string;
    dataCarrierType?: Product.DataCarrierTypeEnum;
    description?: string;
    dyeClass?: string;
    dyeClassStandard?: Product.DyeClassStandardEnum;
    facebookTag?: string;
    finalNetWeight?: number;
    gender?: Product.GenderEnum;
    id?: number;
    inkTypes?: Array<ProductInkType>;
    instagramTag?: string;
    isFeatured?: boolean;
    isFeaturedInCollections?: boolean;
    isMadeByVerified?: boolean;
    lastUpdate?: string;
    mainProductColor?: string;
    mandatoryCircleCompletion?: number;
    manufacturingFacilityName?: string;
    manufacturingFacilityRegistry?: Product.ManufacturingFacilityRegistryEnum;
    materialListUsed?: Product.MaterialListUsedEnum;
    materialTraceabilityProvider?: string;
    name?: string;
    ordering?: number;
    permalink?: string;
    previewCode: string;
    price?: number;
    productCategory?: Product.ProductCategoryEnum;
    productIdSystem?: Product.ProductIdSystemEnum;
    productIdentifier?: string;
    productLinks: Array<ProductUrl>;
    seasonOfSale?: string;
    seoDescription?: string;
    shipFrom?: Country;
    shortDescription?: string;
    size?: string;
    sizeCountry?: Country;
    status?: Product.StatusEnum;
    syncRemoteId?: string;
    syncRemoteLastUpdate?: string;
    synced?: boolean;
    thumbnailProfileImage?: Image;
    twitterTag?: string;
    type?: Product.TypeEnum;
    videoId?: string;
    wholePart?: Product;
    yearOfSale?: string;
}
export namespace Product {
    export type AgeGroupEnum = 'Adult' | 'All' | 'Child' | 'ChildOneToTwoYears' | 'ChildTwoYearsOnwards' | 'Infant' | 'Other' | 'Teen' | 'Toddler' | 'Unclassified' | 'Unidentified' | 'YoungAdult';
    export const AgeGroupEnum = {
        Adult: 'Adult' as AgeGroupEnum,
        All: 'All' as AgeGroupEnum,
        Child: 'Child' as AgeGroupEnum,
        ChildOneToTwoYears: 'ChildOneToTwoYears' as AgeGroupEnum,
        ChildTwoYearsOnwards: 'ChildTwoYearsOnwards' as AgeGroupEnum,
        Infant: 'Infant' as AgeGroupEnum,
        Other: 'Other' as AgeGroupEnum,
        Teen: 'Teen' as AgeGroupEnum,
        Toddler: 'Toddler' as AgeGroupEnum,
        Unclassified: 'Unclassified' as AgeGroupEnum,
        Unidentified: 'Unidentified' as AgeGroupEnum,
        YoungAdult: 'YoungAdult' as AgeGroupEnum
    };
    export type BodyFabricTypeEnum = 'Felt' | 'Knitted' | 'Laminated' | 'Leather' | 'NonWoven' | 'Woven';
    export const BodyFabricTypeEnum = {
        Felt: 'Felt' as BodyFabricTypeEnum,
        Knitted: 'Knitted' as BodyFabricTypeEnum,
        Laminated: 'Laminated' as BodyFabricTypeEnum,
        Leather: 'Leather' as BodyFabricTypeEnum,
        NonWoven: 'NonWoven' as BodyFabricTypeEnum,
        Woven: 'Woven' as BodyFabricTypeEnum
    };
    export type CategorizationStandardEnum = 'GS1' | 'HiggsIndex';
    export const CategorizationStandardEnum = {
        Gs1: 'GS1' as CategorizationStandardEnum,
        HiggsIndex: 'HiggsIndex' as CategorizationStandardEnum
    };
    export type ColorCategoryEnum = 'Black' | 'Blue' | 'Brown' | 'CreamIvory' | 'DarkBlack' | 'DarkBlue' | 'DarkBrown' | 'DarkGold' | 'DarkGreen' | 'DarkGrey' | 'DarkMulti' | 'DarkOrange' | 'DarkPink' | 'DarkPurple' | 'DarkRed' | 'DarkSilver' | 'DarkTan' | 'DarkYellow' | 'Gold' | 'Green' | 'Grey' | 'LightBlack' | 'LightBlue' | 'LightBrown' | 'LightGold' | 'LightGreen' | 'LightGrey' | 'LightMulti' | 'LightOrange' | 'LightPink' | 'LightPurple' | 'LightRed' | 'LightSilver' | 'LightTan' | 'LightYellow' | 'Multi' | 'Orange' | 'Pink' | 'Purple' | 'Red' | 'Silver' | 'Tan' | 'White' | 'Yellow';
    export const ColorCategoryEnum = {
        Black: 'Black' as ColorCategoryEnum,
        Blue: 'Blue' as ColorCategoryEnum,
        Brown: 'Brown' as ColorCategoryEnum,
        CreamIvory: 'CreamIvory' as ColorCategoryEnum,
        DarkBlack: 'DarkBlack' as ColorCategoryEnum,
        DarkBlue: 'DarkBlue' as ColorCategoryEnum,
        DarkBrown: 'DarkBrown' as ColorCategoryEnum,
        DarkGold: 'DarkGold' as ColorCategoryEnum,
        DarkGreen: 'DarkGreen' as ColorCategoryEnum,
        DarkGrey: 'DarkGrey' as ColorCategoryEnum,
        DarkMulti: 'DarkMulti' as ColorCategoryEnum,
        DarkOrange: 'DarkOrange' as ColorCategoryEnum,
        DarkPink: 'DarkPink' as ColorCategoryEnum,
        DarkPurple: 'DarkPurple' as ColorCategoryEnum,
        DarkRed: 'DarkRed' as ColorCategoryEnum,
        DarkSilver: 'DarkSilver' as ColorCategoryEnum,
        DarkTan: 'DarkTan' as ColorCategoryEnum,
        DarkYellow: 'DarkYellow' as ColorCategoryEnum,
        Gold: 'Gold' as ColorCategoryEnum,
        Green: 'Green' as ColorCategoryEnum,
        Grey: 'Grey' as ColorCategoryEnum,
        LightBlack: 'LightBlack' as ColorCategoryEnum,
        LightBlue: 'LightBlue' as ColorCategoryEnum,
        LightBrown: 'LightBrown' as ColorCategoryEnum,
        LightGold: 'LightGold' as ColorCategoryEnum,
        LightGreen: 'LightGreen' as ColorCategoryEnum,
        LightGrey: 'LightGrey' as ColorCategoryEnum,
        LightMulti: 'LightMulti' as ColorCategoryEnum,
        LightOrange: 'LightOrange' as ColorCategoryEnum,
        LightPink: 'LightPink' as ColorCategoryEnum,
        LightPurple: 'LightPurple' as ColorCategoryEnum,
        LightRed: 'LightRed' as ColorCategoryEnum,
        LightSilver: 'LightSilver' as ColorCategoryEnum,
        LightTan: 'LightTan' as ColorCategoryEnum,
        LightYellow: 'LightYellow' as ColorCategoryEnum,
        Multi: 'Multi' as ColorCategoryEnum,
        Orange: 'Orange' as ColorCategoryEnum,
        Pink: 'Pink' as ColorCategoryEnum,
        Purple: 'Purple' as ColorCategoryEnum,
        Red: 'Red' as ColorCategoryEnum,
        Silver: 'Silver' as ColorCategoryEnum,
        Tan: 'Tan' as ColorCategoryEnum,
        White: 'White' as ColorCategoryEnum,
        Yellow: 'Yellow' as ColorCategoryEnum
    };
    export type ConditionEnum = 'New' | 'Rental' | 'SecondHand' | 'Mend';
    export const ConditionEnum = {
        New: 'New' as ConditionEnum,
        Rental: 'Rental' as ConditionEnum,
        SecondHand: 'SecondHand' as ConditionEnum,
    Mend: 'Mend' as ConditionEnum
    };
    export type DataCarrierTypeEnum = 'NFC' | 'QRCode' | 'RFID';
    export const DataCarrierTypeEnum = {
        Nfc: 'NFC' as DataCarrierTypeEnum,
        QrCode: 'QRCode' as DataCarrierTypeEnum,
        Rfid: 'RFID' as DataCarrierTypeEnum
    };
    export type DyeClassStandardEnum = 'EbizEuratex' | 'GS1';
    export const DyeClassStandardEnum = {
        EbizEuratex: 'EbizEuratex' as DyeClassStandardEnum,
        Gs1: 'GS1' as DyeClassStandardEnum
    };
    export type GenderEnum = 'Female' | 'Male' | 'Other' | 'UniSex';
    export const GenderEnum = {
        Female: 'Female' as GenderEnum,
        Male: 'Male' as GenderEnum,
        Other: 'Other' as GenderEnum,
        UniSex: 'UniSex' as GenderEnum
    };
    export type ManufacturingFacilityRegistryEnum = 'GLN' | 'None' | 'OAR';
    export const ManufacturingFacilityRegistryEnum = {
        Gln: 'GLN' as ManufacturingFacilityRegistryEnum,
        None: 'None' as ManufacturingFacilityRegistryEnum,
        Oar: 'OAR' as ManufacturingFacilityRegistryEnum
    };
    export type MaterialListUsedEnum = 'GS1' | 'HiggsIndex';
    export const MaterialListUsedEnum = {
        Gs1: 'GS1' as MaterialListUsedEnum,
        HiggsIndex: 'HiggsIndex' as MaterialListUsedEnum
    };
    export type ProductCategoryEnum = 'ApparelAccessories' | 'BaseLayer' | 'Blanket' | 'BootsNonSteelToe' | 'BootsSteelToe' | 'Cleats' | 'Comforter' | 'Court' | 'Cushion' | 'Dress' | 'DressShoesAndHeels' | 'Duvet' | 'DuvetCover' | 'Hosiery' | 'Jacket' | 'Jersey' | 'KitchenTowel' | 'LeggingOrTights' | 'LightingShade' | 'Mat' | 'MattressPad' | 'Napkin' | 'Other' | 'OtherAthleticHeel' | 'Pants' | 'Pillow' | 'PlaceMat' | 'Quilt' | 'Rug' | 'Sandals' | 'Sham' | 'SheetSet' | 'Shirt' | 'Shower' | 'Skirt' | 'Slipcover' | 'Sneakers' | 'Socks' | 'Sweater' | 'Swimsuit' | 'TShirt' | 'TableCloth' | 'Towel' | 'Underwear' | 'Upholstery' | 'WindowCurtain';
    export const ProductCategoryEnum = {
        ApparelAccessories: 'ApparelAccessories' as ProductCategoryEnum,
        BaseLayer: 'BaseLayer' as ProductCategoryEnum,
        Blanket: 'Blanket' as ProductCategoryEnum,
        BootsNonSteelToe: 'BootsNonSteelToe' as ProductCategoryEnum,
        BootsSteelToe: 'BootsSteelToe' as ProductCategoryEnum,
        Cleats: 'Cleats' as ProductCategoryEnum,
        Comforter: 'Comforter' as ProductCategoryEnum,
        Court: 'Court' as ProductCategoryEnum,
        Cushion: 'Cushion' as ProductCategoryEnum,
        Dress: 'Dress' as ProductCategoryEnum,
        DressShoesAndHeels: 'DressShoesAndHeels' as ProductCategoryEnum,
        Duvet: 'Duvet' as ProductCategoryEnum,
        DuvetCover: 'DuvetCover' as ProductCategoryEnum,
        Hosiery: 'Hosiery' as ProductCategoryEnum,
        Jacket: 'Jacket' as ProductCategoryEnum,
        Jersey: 'Jersey' as ProductCategoryEnum,
        KitchenTowel: 'KitchenTowel' as ProductCategoryEnum,
        LeggingOrTights: 'LeggingOrTights' as ProductCategoryEnum,
        LightingShade: 'LightingShade' as ProductCategoryEnum,
        Mat: 'Mat' as ProductCategoryEnum,
        MattressPad: 'MattressPad' as ProductCategoryEnum,
        Napkin: 'Napkin' as ProductCategoryEnum,
        Other: 'Other' as ProductCategoryEnum,
        OtherAthleticHeel: 'OtherAthleticHeel' as ProductCategoryEnum,
        Pants: 'Pants' as ProductCategoryEnum,
        Pillow: 'Pillow' as ProductCategoryEnum,
        PlaceMat: 'PlaceMat' as ProductCategoryEnum,
        Quilt: 'Quilt' as ProductCategoryEnum,
        Rug: 'Rug' as ProductCategoryEnum,
        Sandals: 'Sandals' as ProductCategoryEnum,
        Sham: 'Sham' as ProductCategoryEnum,
        SheetSet: 'SheetSet' as ProductCategoryEnum,
        Shirt: 'Shirt' as ProductCategoryEnum,
        Shower: 'Shower' as ProductCategoryEnum,
        Skirt: 'Skirt' as ProductCategoryEnum,
        Slipcover: 'Slipcover' as ProductCategoryEnum,
        Sneakers: 'Sneakers' as ProductCategoryEnum,
        Socks: 'Socks' as ProductCategoryEnum,
        Sweater: 'Sweater' as ProductCategoryEnum,
        Swimsuit: 'Swimsuit' as ProductCategoryEnum,
        TShirt: 'TShirt' as ProductCategoryEnum,
        TableCloth: 'TableCloth' as ProductCategoryEnum,
        Towel: 'Towel' as ProductCategoryEnum,
        Underwear: 'Underwear' as ProductCategoryEnum,
        Upholstery: 'Upholstery' as ProductCategoryEnum,
        WindowCurtain: 'WindowCurtain' as ProductCategoryEnum
    };
    export type ProductIdSystemEnum = 'Custom' | 'Gtin' | 'ModelId' | 'Sku' | 'StyleNumber';
    export const ProductIdSystemEnum = {
        Custom: 'Custom' as ProductIdSystemEnum,
        Gtin: 'Gtin' as ProductIdSystemEnum,
        ModelId: 'ModelId' as ProductIdSystemEnum,
        Sku: 'Sku' as ProductIdSystemEnum,
        StyleNumber: 'StyleNumber' as ProductIdSystemEnum
    };
    export type StatusEnum = 'Archived' | 'Deleted' | 'Draft' | 'Hidden' | 'Published';
    export const StatusEnum = {
        Archived: 'Archived' as StatusEnum,
        Deleted: 'Deleted' as StatusEnum,
        Draft: 'Draft' as StatusEnum,
        Hidden: 'Hidden' as StatusEnum,
        Published: 'Published' as StatusEnum
    };
    export type TypeEnum = 'Part' | 'Whole';
    export const TypeEnum = {
        Part: 'Part' as TypeEnum,
        Whole: 'Whole' as TypeEnum
    };
}


