/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface IProducerListView { 
    gender?: IProducerListView.GenderEnum;
    id?: number;
    isFeatured?: boolean;
    isMadeByVerified?: boolean;
    memberOf?: IProducerListView.MemberOfEnum;
    name?: string;
    permalink?: string;
    seoDescription?: string;
    shortDescription?: string;
    type?: IProducerListView.TypeEnum;
}
export namespace IProducerListView {
    export type GenderEnum = 'Female' | 'Male' | 'NotApplicable' | 'Other';
    export const GenderEnum = {
        Female: 'Female' as GenderEnum,
        Male: 'Male' as GenderEnum,
        NotApplicable: 'NotApplicable' as GenderEnum,
        Other: 'Other' as GenderEnum
    };
    export type MemberOfEnum = 'Company' | 'Cooperative' | 'Group' | 'None';
    export const MemberOfEnum = {
        Company: 'Company' as MemberOfEnum,
        Cooperative: 'Cooperative' as MemberOfEnum,
        Group: 'Group' as MemberOfEnum,
        None: 'None' as MemberOfEnum
    };
    export type TypeEnum = 'Artisan' | 'Brand' | 'Other';
    export const TypeEnum = {
        Artisan: 'Artisan' as TypeEnum,
        Brand: 'Brand' as TypeEnum,
        Other: 'Other' as TypeEnum
    };
}


