import { NgModule, LOCALE_ID } from '@angular/core';
import { DatePipe, registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import locale from '@angular/common/locales/en';
import * as moment from 'moment';

import { fontAwesomeIcons } from './icons/font-awesome-icons';
import {CookieModule} from 'ngx-cookie';
import {NgxWebstorageModule} from 'ngx-webstorage';
import {AuthInterceptor} from './interceptor/auth.interceptor';
import {AuthExpiredInterceptor} from './interceptor/auth-expired.interceptor';

@NgModule({
  imports: [
    HttpClientModule,
    CookieModule.forRoot(),
    NgxWebstorageModule.forRoot({ prefix: 'jhi', separator: '-' }),
  ],
  providers: [
    Title,
    {
      provide: LOCALE_ID,
      useValue: 'en'
    },
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthExpiredInterceptor,
      multi: true
    }
  ]
})
export class TheseHandsWebsiteCoreModule {
  constructor(iconLibrary: FaIconLibrary) {
    registerLocaleData(locale);
    // iconLibrary.addIconPacks(fas);
    // iconLibrary.addIcons(...fontAwesomeIcons);
  }
}
