import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { CoreMaterialInProductService } from './api/coreMaterialInProduct.service';
import { DashboardsService } from './api/dashboards.service';
import { DocumentsRepoService } from './api/documentsRepo.service';
import { IP2LocationService } from './api/iP2Location.service';
import { ImagesRepoService } from './api/imagesRepo.service';
import { LoggedUserService } from './api/loggedUser.service';
import { PasswordResetService } from './api/passwordReset.service';
import { ProductInkTypeService } from './api/productInkType.service';
import { QrCodeControllerService } from './api/qrCodeController.service';
import { RegisterService } from './api/register.service';
import { TokenEndpointService } from './api/tokenEndpoint.service';
import { TrackingEventControllerService } from './api/trackingEventController.service';
import { TrackingPixelControllerService } from './api/trackingPixelController.service';
import { WebBlogService } from './api/webBlog.service';
import { WebCollectionsService } from './api/webCollections.service';
import { WebHomePageService } from './api/webHomePage.service';
import { WebProducersService } from './api/webProducers.service';
import { WebProductsService } from './api/webProducts.service';
import { WebSettingsService } from './api/webSettings.service';
import { WebTextService } from './api/webText.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
