import {AfterViewInit, Component, OnInit, ViewEncapsulation} from '@angular/core';

declare var jQuery;

@Component({
  selector: 'app-arrow-top',
  templateUrl: './arrow-top.component.html',
  styleUrls: ['./arrow-top.component.scss']
})
export class ArrowTopComponent implements OnInit, AfterViewInit {

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    // initialization of go to
    jQuery.HSCore.components.HSGoTo.init('.js-go-to');
  }

}
