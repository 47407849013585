/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AddEditProducerValueSubCategoryReqBody { 
    producerId: number;
    status: AddEditProducerValueSubCategoryReqBody.StatusEnum;
    valueSubCategoryId: number;
}
export namespace AddEditProducerValueSubCategoryReqBody {
    export type StatusEnum = 'NotVerified' | 'Verified';
    export const StatusEnum = {
        NotVerified: 'NotVerified' as StatusEnum,
        Verified: 'Verified' as StatusEnum
    };
}


