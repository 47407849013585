/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Product } from './product';
import { Image } from './image';


export interface ProductImage { 
    description?: string;
    id?: number;
    name?: string;
    ordering?: number;
    product?: Product;
    productImage?: Image;
    syncRemoteId?: string;
    syncRemoteLastUpdate?: string;
    synced?: boolean;
    uploader?: ProductImage.UploaderEnum;
}
export namespace ProductImage {
    export type UploaderEnum = 'Artisan' | 'Brand';
    export const UploaderEnum = {
        Artisan: 'Artisan' as UploaderEnum,
        Brand: 'Brand' as UploaderEnum
    };
}


