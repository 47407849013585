import { Component } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RoutesRecognized
} from '@angular/router';
import {AccountService} from './core/auth/account.service';
import {Title} from '@angular/platform-browser';
import {SettingsService} from './shared/services/settings.service';
import {GlobalService} from './shared/services/global.service';
import {MetadataService} from './shared/services/metadata.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  private lastRoute = null;

  constructor(
    private accountService: AccountService,
    private router: Router,
    private settingsService: SettingsService,
    private activatedRoute: ActivatedRoute,
    private metadataService: MetadataService,
    public globalService: GlobalService) {

    this.settingsService.settings.subscribe(settings => {
      // Check identify after loading the settings
      // try to log in automatically
      // this.accountService.identity().subscribe(value => {}, error => {});
    });

    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.clearTitle();
        // console.log('url = ' + event.url);
        let urlPath: string;
        try {
          urlPath = event.url.split('?')[0];
          // console.log('url split = ' + urlPath);
        } catch (e) {
          urlPath = event.url;
        }

        if (urlPath !== this.lastRoute) {
          // Scroll top
          window.scrollTo(0, 0);
        }
        this.lastRoute = urlPath;
      }
      if (event instanceof NavigationEnd) {
        this.updateTitle();
        if (event.url != null && event.url.startsWith('/products/w/')) {
          // hide header, footer, etc.
          this.globalService.setShowScaffolding(false);
        } else {
          // show header, footer, etc.
          this.globalService.setShowScaffolding(true);
        }
      }
      if (event instanceof NavigationError && event.error.status === 404) {
        this.router.navigate(['/404']);
      }
    });
  }

  private getPageTitle(routeSnapshot: ActivatedRouteSnapshot): string {
    let title: string = routeSnapshot.data && routeSnapshot.data['pageTitle'] ? routeSnapshot.data['pageTitle'] : '';
    if (routeSnapshot.firstChild) {
      title = this.getPageTitle(routeSnapshot.firstChild) || title;
    }
    return title;
  }

  private updateTitle(): void {
    const pageTitle = this.getPageTitle(this.router.routerState.snapshot.root);
    if (pageTitle) {
      this.metadataService.setMetadata(pageTitle ? pageTitle : null);
    }
  }

  private clearTitle(): void {
    this.metadataService.setMetadata(null);
  }
}
