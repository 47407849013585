/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IProducerCategoryListView } from './iProducerCategoryListView';


export interface ProducerWithCategoriesListView { 
    categories?: Array<IProducerCategoryListView>;
    gender?: ProducerWithCategoriesListView.GenderEnum;
    id?: number;
    isFeatured?: boolean;
    isMadeByVerified?: boolean;
    memberOf?: ProducerWithCategoriesListView.MemberOfEnum;
    name?: string;
    permalink?: string;
    seoDescription?: string;
    shortDescription?: string;
    type?: ProducerWithCategoriesListView.TypeEnum;
}
export namespace ProducerWithCategoriesListView {
    export type GenderEnum = 'Female' | 'Male' | 'NotApplicable' | 'Other';
    export const GenderEnum = {
        Female: 'Female' as GenderEnum,
        Male: 'Male' as GenderEnum,
        NotApplicable: 'NotApplicable' as GenderEnum,
        Other: 'Other' as GenderEnum
    };
    export type MemberOfEnum = 'Company' | 'Cooperative' | 'Group' | 'None';
    export const MemberOfEnum = {
        Company: 'Company' as MemberOfEnum,
        Cooperative: 'Cooperative' as MemberOfEnum,
        Group: 'Group' as MemberOfEnum,
        None: 'None' as MemberOfEnum
    };
    export type TypeEnum = 'Artisan' | 'Brand' | 'Other';
    export const TypeEnum = {
        Artisan: 'Artisan' as TypeEnum,
        Brand: 'Brand' as TypeEnum,
        Other: 'Other' as TypeEnum
    };
}


