/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface AddEditProducerUrl {
    defaultUrlForType?: boolean;
    description?: string;
    discountCode?: string;
    discountLabel?: string;
    label: string;
    linkType: AddEditProducerUrl.LinkTypeEnum;
    producerId?: number;
    url: string;
}
export namespace AddEditProducerUrl {
    export type LinkTypeEnum = 'BuyNew' | 'BuySecondHand' | 'Rent' | 'Mend';
    export const LinkTypeEnum = {
        BuyNew: 'BuyNew' as LinkTypeEnum,
        BuySecondHand: 'BuySecondHand' as LinkTypeEnum,
        Rent: 'Rent' as LinkTypeEnum,
      Mend: 'Mend' as LinkTypeEnum
    };
}


