import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  public showScaffolding = new BehaviorSubject<boolean>(true);

  constructor() {
  }

  public setShowScaffolding(showScaffolding: boolean): void {
    this.showScaffolding.next(showScaffolding);
  }
}
