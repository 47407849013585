import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable, ReplaySubject, of } from 'rxjs';
import { shareReplay, tap, catchError } from 'rxjs/operators';
import {StateStorageService} from './state-storage.service';
import {environment} from '../../../environments/environment';
import {Account, AccountRole} from '../user/account.model';
import {AuthServerProvider} from './auth-jwt.service';

@Injectable({ providedIn: 'root' })
export class AccountService {
  private userIdentity: Account | null = null;
  private authenticationState = new ReplaySubject<Account | null>(1);
  private accountCache$?: Observable<Account | null>;

  constructor(private http: HttpClient, private stateStorageService: StateStorageService, private router: Router, private authServerProvider: AuthServerProvider) {}

  save(account: Account): Observable<{}> {
    return this.http.post(environment.basePath + '/api/v1/me', account);
  }

  authenticate(identity: Account | null): void {
    this.userIdentity = identity;
    this.authenticationState.next(this.userIdentity);
  }

  hasAnyAuthority(authorities: string[] | string): boolean {
    /*if (!this.userIdentity || !this.userIdentity.authorities) {
      return false;
    }
    if (!Array.isArray(authorities)) {
      authorities = [authorities];
    }
    return this.userIdentity.authorities.some((authority: string) => authorities.includes(authority));*/
    if (!this.userIdentity || !this.userIdentity.roles) {
      return false;
    }
    if (!Array.isArray(authorities)) {
      authorities = [authorities];
    }
    return this.userIdentity.roles.some((role: AccountRole) => authorities.includes(role.name));
  }

  identity(force?: boolean): Observable<Account | null> {
    if (!this.accountCache$ || force || !this.isAuthenticated()) {
      this.accountCache$ = this.fetch().pipe(
        catchError(() => {
          return of(null);
        }),
        tap((account: Account | null) => {
          this.authenticate(account);

          if (account) {
            this.navigateToStoredUrl();
          }
        }),
        shareReplay()
      );
    }
    return this.accountCache$;
  }

  isAuthenticated(): boolean {
    // return this.userIdentity !== null;
    const token = this.authServerProvider.getToken();
    return token !== null && token !== '';
  }

  getAuthenticationState(): Observable<Account | null> {
    return this.authenticationState.asObservable();
  }

  // TODO reactivate when it's needed
  /*getImageUrl(): string {
    return this.userIdentity ? this.userIdentity.imageUrl : '';
  }*/

  private fetch(): Observable<Account> {
    const headers = new HttpHeaders(
      {
        Authorization: 'Bearer ' + this.authServerProvider.getToken(),
      }
    );
    return this.http.get<Account>(environment.basePath + '/api/v1/me', {headers});
  }

  private navigateToStoredUrl(): void {
    // previousState can be set in the authExpiredInterceptor and in the userRouteAccessService
    // if login is successful, go to stored previousState and clear previousState
    const previousUrl = this.stateStorageService.getUrl();
    if (previousUrl) {
      this.stateStorageService.clearUrl();
      this.router.navigateByUrl(previousUrl);
    }
  }
}
