/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ArticleImage } from './articleImage';
import { Image } from './image';
import { Topic } from './topic';


export interface Article { 
    created?: string;
    creatorName?: string;
    headerProfileImage?: Image;
    id?: number;
    images: Set<ArticleImage>;
    isFeatured?: boolean;
    lastUpdate?: string;
    mainTopic?: string;
    name?: string;
    permalink?: string;
    published?: string;
    shortDescription?: string;
    status?: Article.StatusEnum;
    text?: string;
    thumbnailProfileImage?: Image;
    topic?: Topic;
    type?: Article.TypeEnum;
    url?: string;
    videoId?: string;
}
export namespace Article {
    export type StatusEnum = 'Archived' | 'Deleted' | 'Draft' | 'Hidden' | 'Published';
    export const StatusEnum = {
        Archived: 'Archived' as StatusEnum,
        Deleted: 'Deleted' as StatusEnum,
        Draft: 'Draft' as StatusEnum,
        Hidden: 'Hidden' as StatusEnum,
        Published: 'Published' as StatusEnum
    };
    export type TypeEnum = 'Craft' | 'Material' | 'News' | 'Tutorial';
    export const TypeEnum = {
        Craft: 'Craft' as TypeEnum,
        Material: 'Material' as TypeEnum,
        News: 'News' as TypeEnum,
        Tutorial: 'Tutorial' as TypeEnum
    };
}


