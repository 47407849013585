/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProductMaterial } from './productMaterial';
import { ProductHasMaterialId } from './productHasMaterialId';
import { Product } from './product';


export interface ProductHasMaterial { 
    id?: ProductHasMaterialId;
    product: Product;
    productMaterial: ProductMaterial;
    status?: ProductHasMaterial.StatusEnum;
}
export namespace ProductHasMaterial {
    export type StatusEnum = 'NotVerified' | 'Verified';
    export const StatusEnum = {
        NotVerified: 'NotVerified' as StatusEnum,
        Verified: 'Verified' as StatusEnum
    };
}


