import {AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import {LoginService} from '../../core/login/login.service';
import {AccountService} from '../../core/auth/account.service';
import {Router} from '@angular/router';

declare var jQuery;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit {

  constructor(
    private loginService: LoginService,
    public accountService: AccountService,
    private router: Router) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    jQuery(window).on('load', function () {
      // initialization of header
      jQuery.HSCore.components.HSHeader.init(jQuery('#js-header'));
      jQuery.HSCore.helpers.HSHamburgers.init('.hamburger');

      // initialization of HSMegaMenu component
      jQuery('.js-mega-menu').HSMegaMenu({
        event: 'hover',
        pageContainer: jQuery('.container'),
        breakpoint: 991
      });
    });
  }

  isAuthenticated(): boolean {
    return this.accountService.isAuthenticated();
  }

  logout(): void {
    this.loginService.logout();
    // this.router.navigate(['/']);
    this.router.navigateByUrl('/');
  }

  toggleMenu(): void {
    const toggler = jQuery('.hamburger');
    if (toggler.is(':visible')) {
      toggler.click();
      toggler.removeClass('is-active');
    }
  }
}
