/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AddEditCoreMaterial { 
    code: string;
    materialList: AddEditCoreMaterial.MaterialListEnum;
    name: string;
}
export namespace AddEditCoreMaterial {
    export type MaterialListEnum = 'GS1' | 'HiggsIndex';
    export const MaterialListEnum = {
        Gs1: 'GS1' as MaterialListEnum,
        HiggsIndex: 'HiggsIndex' as MaterialListEnum
    };
}


