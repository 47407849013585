/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AddEditProductLocationReqBody { 
    countryId: number;
    date?: string;
    description?: string;
    latitude: number;
    longitude: number;
    materialsUsed?: string;
    name: string;
    ordering?: number;
    productId: number;
    status: AddEditProductLocationReqBody.StatusEnum;
}
export namespace AddEditProductLocationReqBody {
    export type StatusEnum = 'NotVerified' | 'Verified';
    export const StatusEnum = {
        NotVerified: 'NotVerified' as StatusEnum,
        Verified: 'Verified' as StatusEnum
    };
}


