/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {ProducerWithCategoriesListView} from './producerWithCategoriesListView';
import {ProductUrl} from './productUrl';


export interface ProductDetailWithProducerCategoriesView {
  artisan?: ProducerWithCategoriesListView;
  brand?: ProducerWithCategoriesListView;
  currency?: string;
  description?: string;
  facebookTag?: string;
  id?: number;
  instagramTag?: string;
  isFeatured?: boolean;
  isMadeByVerified?: boolean;
  name?: string;
  permalink?: string;
  price?: number;
  productLinks?: Array<ProductUrl>;
  seoDescription?: string;
  shortDescription?: string;
  twitterTag?: string;
  type?: ProductDetailWithProducerCategoriesView.TypeEnum;
  envImpactEnabled: boolean;
  envImpactCo2Emissions?: number;
  envImpactCo2EmissionsDetails?: string;
  envImpactWaterFootprint?: number;
  envImpactWaterFootprintDetails?: string;
  envImpactFabricWaste?: number;
  envImpactFabricWasteDetails?: string;
}

export namespace ProductDetailWithProducerCategoriesView {
  export type TypeEnum = 'Part' | 'Whole';
  export const TypeEnum = {
    Part: 'Part' as TypeEnum,
    Whole: 'Whole' as TypeEnum
  };
}


