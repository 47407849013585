import {Injectable, OnDestroy, OnInit} from '@angular/core';
import {SubSink} from 'subsink';
import {SettingsWrapper, WebSettingsService} from '../../api';
import {BehaviorSubject} from 'rxjs';
import {LocalStorageService} from 'ngx-webstorage';
import {LocallyStoredService} from './common/locally-stored.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsService extends LocallyStoredService implements OnDestroy {

  public settings = new BehaviorSubject<SettingsWrapper>({});

  private subs = new SubSink();

  constructor(private webSettingsService: WebSettingsService, private $localStorage: LocalStorageService) {
    super($localStorage);

    if (this.isCacheValid()) {
      this.settings.next(this.getFromCache());
    } else {
      this.loadData();
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  protected getPrefix(): string {
    return 'settings';
  }

  loadData(): void {
    console.log('Loading web settings');
    this.webSettingsService.getSettingsUsingGET(true)
      .subscribe(resp => {
        if (resp) {
          this.settings.next(resp);
          this.saveToCache(resp);
        }
      }, error => {
        console.error(error);
      });
  }
}
