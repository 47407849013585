/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { PageProducerWithCategoriesListView } from '../model/models';
import { PageProductWithCategoriesListView } from '../model/models';
import { ProductDetailWrapper } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class WebProductsService {

    protected basePath = 'http://localhost:8083';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Return the featured brands
     * @param page Results page you want to retrieve (0..N)
     * @param size Number of records per page.
     * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFeaturedBrandsUsingGET(page?: any, size?: any, sort?: any, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<PageProducerWithCategoriesListView>;
    public getFeaturedBrandsUsingGET(page?: any, size?: any, sort?: any, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<PageProducerWithCategoriesListView>>;
    public getFeaturedBrandsUsingGET(page?: any, size?: any, sort?: any, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<PageProducerWithCategoriesListView>>;
    public getFeaturedBrandsUsingGET(page?: any, size?: any, sort?: any, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (page !== undefined && page !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>page, 'page');
        }
        if (size !== undefined && size !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>size, 'size');
        }
        if (sort !== undefined && sort !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>sort, 'sort');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<PageProducerWithCategoriesListView>(`${this.configuration.basePath}/api/v1/website/web-products/featured-brands`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Check if the product exists
     * @param permalink The product permalink
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductExistsUsingGET(permalink: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'image/png'}): Observable<string>;
    public getProductExistsUsingGET(permalink: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'image/png'}): Observable<HttpResponse<string>>;
    public getProductExistsUsingGET(permalink: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'image/png'}): Observable<HttpEvent<string>>;
    public getProductExistsUsingGET(permalink: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'image/png'}): Observable<any> {
        if (permalink === null || permalink === undefined) {
            throw new Error('Required parameter permalink was null or undefined when calling getProductExistsUsingGET.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'image/png'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<string>(`${this.configuration.basePath}/api/v1/website/web-products/products/${encodeURIComponent(String(permalink))}/exists.png`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Return the product details
     * @param permalinkOrPreviewCode The product permalink or preview code
     * @param cdp circular data protocol fields
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param options
     */
    public getProductUsingGET(permalinkOrPreviewCode: string, cdp: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<ProductDetailWrapper>;
    public getProductUsingGET(permalinkOrPreviewCode: string, cdp: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<ProductDetailWrapper>>;
    public getProductUsingGET(permalinkOrPreviewCode: string, cdp: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<ProductDetailWrapper>>;
    public getProductUsingGET(permalinkOrPreviewCode: string, cdp: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (permalinkOrPreviewCode === null || permalinkOrPreviewCode === undefined) {
            throw new Error('Required parameter permalinkOrPreviewCode was null or undefined when calling getProductUsingGET.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

      let queryParameters = new HttpParams({encoder: this.encoder});
      if (cdp !== undefined && cdp !== null && cdp === true) {
        queryParameters = this.addToHttpParams(queryParameters,
          <any> cdp, 'cdp');
      }

        return this.httpClient.get<ProductDetailWrapper>(`${this.configuration.basePath}/api/v1/website/web-products/products/${encodeURIComponent(String(permalinkOrPreviewCode))}`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Return the products
     * @param brandPermalink The brand permalink filter
     * @param categories The categories filter
     * @param certifications The certifications filter
     * @param crafts The crafts filter
     * @param materialCategories The material categories filter
     * @param maxPrice The maxPrice filter
     * @param minPrice The minPrice filter
     * @param orderBy The order type
     * @param orderByDirection The order direction
     * @param page Results page you want to retrieve (0..N)
     * @param search search
     * @param size Number of records per page.
     * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param values The values filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductsUsingGET(brandPermalink?: string, categories?: string, certifications?: string, crafts?: string, materialCategories?: string, maxPrice?: number, minPrice?: number, orderBy?: 'created' | 'price' | 'random', orderByDirection?: 'ASC' | 'DESC', page?: any, search?: string, verifiedOnly?: boolean, size?: any, sort?: any, values?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<PageProductWithCategoriesListView>;
    public getProductsUsingGET(brandPermalink?: string, categories?: string, certifications?: string, crafts?: string, materialCategories?: string, maxPrice?: number, minPrice?: number, orderBy?: 'created' | 'price' | 'random', orderByDirection?: 'ASC' | 'DESC', page?: any, search?: string, verifiedOnly?: boolean, size?: any, sort?: any, values?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<PageProductWithCategoriesListView>>;
    public getProductsUsingGET(brandPermalink?: string, categories?: string, certifications?: string, crafts?: string, materialCategories?: string, maxPrice?: number, minPrice?: number, orderBy?: 'created' | 'price' | 'random', orderByDirection?: 'ASC' | 'DESC', page?: any, search?: string, verifiedOnly?: boolean, size?: any, sort?: any, values?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<PageProductWithCategoriesListView>>;
    public getProductsUsingGET(brandPermalink?: string, categories?: string, certifications?: string, crafts?: string, materialCategories?: string, maxPrice?: number, minPrice?: number, orderBy?: 'created' | 'price' | 'random', orderByDirection?: 'ASC' | 'DESC', page?: any, search?: string, verifiedOnly?: boolean, size?: any, sort?: any, values?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (brandPermalink !== undefined && brandPermalink !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>brandPermalink, 'brandPermalink');
        }
        if (categories !== undefined && categories !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>categories, 'categories');
        }
        if (certifications !== undefined && certifications !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>certifications, 'certifications');
        }
        if (crafts !== undefined && crafts !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>crafts, 'crafts');
        }
        if (materialCategories !== undefined && materialCategories !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>materialCategories, 'materialCategories');
        }
        if (maxPrice !== undefined && maxPrice !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>maxPrice, 'maxPrice');
        }
        if (minPrice !== undefined && minPrice !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>minPrice, 'minPrice');
        }
        if (orderBy !== undefined && orderBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>orderBy, 'orderBy');
        }
        if (orderByDirection !== undefined && orderByDirection !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>orderByDirection, 'orderByDirection');
        }
        if (page !== undefined && page !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>page, 'page');
        }
        if (search !== undefined && search !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>search, 'search');
        }
        if (verifiedOnly !== undefined && verifiedOnly !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>verifiedOnly, 'verifiedOnly');
        }
        if (size !== undefined && size !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>size, 'size');
        }
        if (sort !== undefined && sort !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>sort, 'sort');
        }
        if (values !== undefined && values !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>values, 'values');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<PageProductWithCategoriesListView>(`${this.configuration.basePath}/api/v1/website/web-products/products`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
