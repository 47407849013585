/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AddEditProductInkType { 
    inkType: AddEditProductInkType.InkTypeEnum;
    productId?: number;
}
export namespace AddEditProductInkType {
    export type InkTypeEnum = 'Acid' | 'Custom' | 'Digital' | 'Discharge' | 'Disperse' | 'HighSolidsAcrylic' | 'Pigment' | 'Plastisol' | 'Reactive' | 'Sublimation' | 'WaterBased';
    export const InkTypeEnum = {
        Acid: 'Acid' as InkTypeEnum,
        Custom: 'Custom' as InkTypeEnum,
        Digital: 'Digital' as InkTypeEnum,
        Discharge: 'Discharge' as InkTypeEnum,
        Disperse: 'Disperse' as InkTypeEnum,
        HighSolidsAcrylic: 'HighSolidsAcrylic' as InkTypeEnum,
        Pigment: 'Pigment' as InkTypeEnum,
        Plastisol: 'Plastisol' as InkTypeEnum,
        Reactive: 'Reactive' as InkTypeEnum,
        Sublimation: 'Sublimation' as InkTypeEnum,
        WaterBased: 'WaterBased' as InkTypeEnum
    };
}


