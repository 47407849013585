/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ResponseEntity { 
    body?: object;
    statusCode?: ResponseEntity.StatusCodeEnum;
    statusCodeValue?: number;
}
export namespace ResponseEntity {
    export type StatusCodeEnum = 'ACCEPTED' | 'ALREADY_REPORTED' | 'BAD_GATEWAY' | 'BAD_REQUEST' | 'BANDWIDTH_LIMIT_EXCEEDED' | 'CHECKPOINT' | 'CONFLICT' | 'CONTINUE' | 'CREATED' | 'DESTINATION_LOCKED' | 'EXPECTATION_FAILED' | 'FAILED_DEPENDENCY' | 'FORBIDDEN' | 'FOUND' | 'GATEWAY_TIMEOUT' | 'GONE' | 'HTTP_VERSION_NOT_SUPPORTED' | 'IM_USED' | 'INSUFFICIENT_SPACE_ON_RESOURCE' | 'INSUFFICIENT_STORAGE' | 'INTERNAL_SERVER_ERROR' | 'I_AM_A_TEAPOT' | 'LENGTH_REQUIRED' | 'LOCKED' | 'LOOP_DETECTED' | 'METHOD_FAILURE' | 'METHOD_NOT_ALLOWED' | 'MOVED_PERMANENTLY' | 'MOVED_TEMPORARILY' | 'MULTIPLE_CHOICES' | 'MULTI_STATUS' | 'NETWORK_AUTHENTICATION_REQUIRED' | 'NON_AUTHORITATIVE_INFORMATION' | 'NOT_ACCEPTABLE' | 'NOT_EXTENDED' | 'NOT_FOUND' | 'NOT_IMPLEMENTED' | 'NOT_MODIFIED' | 'NO_CONTENT' | 'OK' | 'PARTIAL_CONTENT' | 'PAYLOAD_TOO_LARGE' | 'PAYMENT_REQUIRED' | 'PERMANENT_REDIRECT' | 'PRECONDITION_FAILED' | 'PRECONDITION_REQUIRED' | 'PROCESSING' | 'PROXY_AUTHENTICATION_REQUIRED' | 'REQUESTED_RANGE_NOT_SATISFIABLE' | 'REQUEST_ENTITY_TOO_LARGE' | 'REQUEST_HEADER_FIELDS_TOO_LARGE' | 'REQUEST_TIMEOUT' | 'REQUEST_URI_TOO_LONG' | 'RESET_CONTENT' | 'SEE_OTHER' | 'SERVICE_UNAVAILABLE' | 'SWITCHING_PROTOCOLS' | 'TEMPORARY_REDIRECT' | 'TOO_EARLY' | 'TOO_MANY_REQUESTS' | 'UNAUTHORIZED' | 'UNAVAILABLE_FOR_LEGAL_REASONS' | 'UNPROCESSABLE_ENTITY' | 'UNSUPPORTED_MEDIA_TYPE' | 'UPGRADE_REQUIRED' | 'URI_TOO_LONG' | 'USE_PROXY' | 'VARIANT_ALSO_NEGOTIATES';
    export const StatusCodeEnum = {
        Accepted: 'ACCEPTED' as StatusCodeEnum,
        AlreadyReported: 'ALREADY_REPORTED' as StatusCodeEnum,
        BadGateway: 'BAD_GATEWAY' as StatusCodeEnum,
        BadRequest: 'BAD_REQUEST' as StatusCodeEnum,
        BandwidthLimitExceeded: 'BANDWIDTH_LIMIT_EXCEEDED' as StatusCodeEnum,
        Checkpoint: 'CHECKPOINT' as StatusCodeEnum,
        Conflict: 'CONFLICT' as StatusCodeEnum,
        Continue: 'CONTINUE' as StatusCodeEnum,
        Created: 'CREATED' as StatusCodeEnum,
        DestinationLocked: 'DESTINATION_LOCKED' as StatusCodeEnum,
        ExpectationFailed: 'EXPECTATION_FAILED' as StatusCodeEnum,
        FailedDependency: 'FAILED_DEPENDENCY' as StatusCodeEnum,
        Forbidden: 'FORBIDDEN' as StatusCodeEnum,
        Found: 'FOUND' as StatusCodeEnum,
        GatewayTimeout: 'GATEWAY_TIMEOUT' as StatusCodeEnum,
        Gone: 'GONE' as StatusCodeEnum,
        HttpVersionNotSupported: 'HTTP_VERSION_NOT_SUPPORTED' as StatusCodeEnum,
        ImUsed: 'IM_USED' as StatusCodeEnum,
        InsufficientSpaceOnResource: 'INSUFFICIENT_SPACE_ON_RESOURCE' as StatusCodeEnum,
        InsufficientStorage: 'INSUFFICIENT_STORAGE' as StatusCodeEnum,
        InternalServerError: 'INTERNAL_SERVER_ERROR' as StatusCodeEnum,
        IAmATeapot: 'I_AM_A_TEAPOT' as StatusCodeEnum,
        LengthRequired: 'LENGTH_REQUIRED' as StatusCodeEnum,
        Locked: 'LOCKED' as StatusCodeEnum,
        LoopDetected: 'LOOP_DETECTED' as StatusCodeEnum,
        MethodFailure: 'METHOD_FAILURE' as StatusCodeEnum,
        MethodNotAllowed: 'METHOD_NOT_ALLOWED' as StatusCodeEnum,
        MovedPermanently: 'MOVED_PERMANENTLY' as StatusCodeEnum,
        MovedTemporarily: 'MOVED_TEMPORARILY' as StatusCodeEnum,
        MultipleChoices: 'MULTIPLE_CHOICES' as StatusCodeEnum,
        MultiStatus: 'MULTI_STATUS' as StatusCodeEnum,
        NetworkAuthenticationRequired: 'NETWORK_AUTHENTICATION_REQUIRED' as StatusCodeEnum,
        NonAuthoritativeInformation: 'NON_AUTHORITATIVE_INFORMATION' as StatusCodeEnum,
        NotAcceptable: 'NOT_ACCEPTABLE' as StatusCodeEnum,
        NotExtended: 'NOT_EXTENDED' as StatusCodeEnum,
        NotFound: 'NOT_FOUND' as StatusCodeEnum,
        NotImplemented: 'NOT_IMPLEMENTED' as StatusCodeEnum,
        NotModified: 'NOT_MODIFIED' as StatusCodeEnum,
        NoContent: 'NO_CONTENT' as StatusCodeEnum,
        Ok: 'OK' as StatusCodeEnum,
        PartialContent: 'PARTIAL_CONTENT' as StatusCodeEnum,
        PayloadTooLarge: 'PAYLOAD_TOO_LARGE' as StatusCodeEnum,
        PaymentRequired: 'PAYMENT_REQUIRED' as StatusCodeEnum,
        PermanentRedirect: 'PERMANENT_REDIRECT' as StatusCodeEnum,
        PreconditionFailed: 'PRECONDITION_FAILED' as StatusCodeEnum,
        PreconditionRequired: 'PRECONDITION_REQUIRED' as StatusCodeEnum,
        Processing: 'PROCESSING' as StatusCodeEnum,
        ProxyAuthenticationRequired: 'PROXY_AUTHENTICATION_REQUIRED' as StatusCodeEnum,
        RequestedRangeNotSatisfiable: 'REQUESTED_RANGE_NOT_SATISFIABLE' as StatusCodeEnum,
        RequestEntityTooLarge: 'REQUEST_ENTITY_TOO_LARGE' as StatusCodeEnum,
        RequestHeaderFieldsTooLarge: 'REQUEST_HEADER_FIELDS_TOO_LARGE' as StatusCodeEnum,
        RequestTimeout: 'REQUEST_TIMEOUT' as StatusCodeEnum,
        RequestUriTooLong: 'REQUEST_URI_TOO_LONG' as StatusCodeEnum,
        ResetContent: 'RESET_CONTENT' as StatusCodeEnum,
        SeeOther: 'SEE_OTHER' as StatusCodeEnum,
        ServiceUnavailable: 'SERVICE_UNAVAILABLE' as StatusCodeEnum,
        SwitchingProtocols: 'SWITCHING_PROTOCOLS' as StatusCodeEnum,
        TemporaryRedirect: 'TEMPORARY_REDIRECT' as StatusCodeEnum,
        TooEarly: 'TOO_EARLY' as StatusCodeEnum,
        TooManyRequests: 'TOO_MANY_REQUESTS' as StatusCodeEnum,
        Unauthorized: 'UNAUTHORIZED' as StatusCodeEnum,
        UnavailableForLegalReasons: 'UNAVAILABLE_FOR_LEGAL_REASONS' as StatusCodeEnum,
        UnprocessableEntity: 'UNPROCESSABLE_ENTITY' as StatusCodeEnum,
        UnsupportedMediaType: 'UNSUPPORTED_MEDIA_TYPE' as StatusCodeEnum,
        UpgradeRequired: 'UPGRADE_REQUIRED' as StatusCodeEnum,
        UriTooLong: 'URI_TOO_LONG' as StatusCodeEnum,
        UseProxy: 'USE_PROXY' as StatusCodeEnum,
        VariantAlsoNegotiates: 'VARIANT_ALSO_NEGOTIATES' as StatusCodeEnum
    };
}


