/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface IProducerDetailView { 
    about?: string;
    contactAddress?: string;
    contactCity?: string;
    contactEmail?: string;
    contactPhone?: string;
    contactWhatsapp?: string;
    description?: string;
    facebookHandler?: string;
    gender?: IProducerDetailView.GenderEnum;
    id?: number;
    instagramHandler?: string;
    isFeatured?: boolean;
    isMadeByVerified?: boolean;
    memberOf?: string;
    name?: string;
    permalink?: string;
    personalMessage?: string;
    personalMessageSignature?: string;
    seoDescription?: string;
    shortDescription?: string;
    twitterHandler?: string;
    videoId?: string;
}
export namespace IProducerDetailView {
    export type GenderEnum = 'Female' | 'Male' | 'NotApplicable' | 'Other';
    export const GenderEnum = {
        Female: 'Female' as GenderEnum,
        Male: 'Male' as GenderEnum,
        NotApplicable: 'NotApplicable' as GenderEnum,
        Other: 'Other' as GenderEnum
    };
}


