import {NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeaderComponent} from './header/header.component';
import {FooterComponent} from './footer/footer.component';
import {ArrowTopComponent} from './arrow-top/arrow-top.component';
import {RouterModule} from '@angular/router';
import {ProductCardComponent} from './product-card/product-card.component';
import {BrandCardComponent} from './brand-card/brand-card.component';
import {FullScreenVideoComponent} from './full-screen-video/full-screen-video.component';
import {ArticleCardComponent} from './article-card/article-card.component';
import {MapPinsComponent} from './map-pins/map-pins.component';
import {ProductTimelineComponent} from './product-timeline/product-timeline.component';
import {ProductTimelineItemComponent} from './product-timeline-item/product-timeline-item.component';
import {LeanImageUrlPipe} from './pipes/lean-image-url.pipe';
import {SingleSelectFilterListComponent} from './single-select-filter-list/single-select-filter-list.component';
import {TagsFilterListComponent} from './tags-filter-list/tags-filter-list.component';
import {MultiSelectFilterListComponent} from './multi-select-filter-list/multi-select-filter-list.component';
import {SortSelectorComponent} from './sort-selector/sort-selector.component';
import {ChunkPipe} from './pipes/chunk.pipe';
import {PaginationBarComponent} from './pagination-bar/pagination-bar.component';
import {SectionHeroComponent} from './section-hero/section-hero.component';
import {StripHtmlPipe} from './pipes/striphtml.pipe';
import {QuoteComponent} from './quote/quote.component';
import {SocialMediaLinksComponent} from './social-media-links/social-media-links.component';
import {FormsModule} from '@angular/forms';
import {TabHeaderComponent} from './tab-header/tab-header.component';
import {ButtonRightIconComponent} from './button-right-icon/button-right-icon.component';
import {ButtonOpeningContentComponent} from './button-opening-content/button-opening-content.component';
import {BreadCrumbsComponent} from './bread-crumbs/bread-crumbs.component';
import {ReadMoreTextComponent} from './read-more-text/read-more-text.component';
import {LinkTabsComponent} from './link-tabs/link-tabs.component';
import {ValuesSubValuesComponent} from './values-sub-values/values-sub-values.component';
import {MadeByVerifiedWidgetBoxComponent} from './made-by-verified-widget-box/made-by-verified-widget-box.component';
import { VerifiedCheckBadgeComponent } from './verified-check-badge/verified-check-badge.component';
import {NewLinesToHtmlPipe} from './pipes/newlinestohtml.pipe';
import {LowerDashPipe} from './pipes/lower-dash.pipe';
import {ToggleSwicthComponent} from './toggle-swicth/toggle-swicth.component';
import {ConvertLinkTypePipe} from './pipes/convert-link-type.pipe';
import { FaqComponent } from './faq/faq.component';


@NgModule({
  declarations: [HeaderComponent, FooterComponent, ArrowTopComponent, ProductCardComponent, BrandCardComponent,
    FullScreenVideoComponent, ArticleCardComponent, MapPinsComponent, ProductTimelineComponent, ProductTimelineItemComponent,
    LeanImageUrlPipe,
    ChunkPipe,
    LowerDashPipe,
    ConvertLinkTypePipe,
    StripHtmlPipe,
    NewLinesToHtmlPipe,
    SingleSelectFilterListComponent,
    TagsFilterListComponent,
    MultiSelectFilterListComponent,
    SortSelectorComponent,
    PaginationBarComponent,
    SectionHeroComponent,
    QuoteComponent,
    TabHeaderComponent,
    SocialMediaLinksComponent,
    ButtonRightIconComponent,
    ButtonOpeningContentComponent, BreadCrumbsComponent, ReadMoreTextComponent, LinkTabsComponent,
    ValuesSubValuesComponent, MadeByVerifiedWidgetBoxComponent, VerifiedCheckBadgeComponent, ToggleSwicthComponent, FaqComponent,
    /*AlertComponent, AlertErrorComponent, HasAnyAuthorityDirective,*/],
  exports: [HeaderComponent, FooterComponent, ArrowTopComponent, FullScreenVideoComponent, ArticleCardComponent,
    BrandCardComponent, ProductCardComponent, MapPinsComponent, ProductTimelineComponent,
    LeanImageUrlPipe,
    ChunkPipe,
    LowerDashPipe,
    ConvertLinkTypePipe,
    StripHtmlPipe,
    NewLinesToHtmlPipe,
    SingleSelectFilterListComponent,
    TagsFilterListComponent,
    MultiSelectFilterListComponent, SortSelectorComponent, PaginationBarComponent,
    SectionHeroComponent,
    QuoteComponent, SocialMediaLinksComponent,
    TabHeaderComponent,
    ButtonRightIconComponent,
    ButtonOpeningContentComponent, BreadCrumbsComponent, ReadMoreTextComponent, LinkTabsComponent,
    ValuesSubValuesComponent, MadeByVerifiedWidgetBoxComponent, VerifiedCheckBadgeComponent, ToggleSwicthComponent, FaqComponent,
    /*AlertComponent, AlertErrorComponent, HasAnyAuthorityDirective,*/],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
    ],
  schemas: [NO_ERRORS_SCHEMA] // we need this for the popover element used in map-pins component
})
export class SharedModule {
}
