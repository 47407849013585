<!-- Header -->
<header id="js-header" class="u-header u-header--static">
  <div class="u-header__section u-header__section--light g-bg-white g-transition-0_3 g-py-10" [ngSwitch]="isAuthenticated()">
    <nav class="js-mega-menu navbar navbar-expand-lg hs-menu-initialized hs-menu-horizontal">
      <div class="container">
        <!-- Responsive Toggle Button -->
        <button class="navbar-toggler navbar-toggler-right btn g-line-height-1 g-brd-none g-pa-0 g-pos-abs g-top-minus-3 g-right-0" type="button" aria-label="Toggle navigation" aria-expanded="false" aria-controls="navBar" data-toggle="collapse" data-target="#navBar">
              <span class="hamburger hamburger--slider">
            <span class="hamburger-box">
              <span class="hamburger-inner"></span>
              </span>
              </span>
        </button>
        <!-- End Responsive Toggle Button -->

        <!-- Logo -->
        <a [routerLink]="['/']" class="navbar-brand d-flex">
          <img src="/assets/madeby/logo.svg" alt="Made By logo" class="made-by-logo"/>
        </a>
        <!-- End Logo -->

        <!-- Navigation -->
        <div class="collapse navbar-collapse align-items-center flex-sm-row g-pt-10 g-pt-5--lg" id="navBar">
          <ul class="navbar-nav text-uppercase g-pos-rel g-font-weight-600 ml-auto">
            <!-- Main Menu -->
            <li class="nav-item  g-mx-10--lg g-mx-15--xl">
              <a class="nav-link g-py-7 g-px-0" [routerLink]="['/meet']">Meet Brands</a>
            </li>
            <li class="nav-item  g-mx-10--lg g-mx-15--xl">
              <a class="nav-link g-py-7 g-px-0" [routerLink]="['/products']" (click)="toggleMenu()">Discover Products</a>
            </li>
            <li class="nav-item  g-mx-10--lg g-mx-15--xl">
              <a class="nav-link g-py-7 g-px-0" [routerLink]="['/edu']">Learn and Explore</a>
            </li>
            <!-- End Main Menu -->

            <!--<li *ngSwitchCase="false" class="nav-item  g-mx-10&#45;&#45;lg g-mx-15&#45;&#45;xl">
              <a class="nav-link g-py-7 g-px-0" [routerLink]="['/auth']">Brand Access</a>
            </li>-->

            <!-- My account -->
            <li *ngSwitchCase="true" class="nav-item hs-has-sub-menu  g-mx-10--lg g-mx-15--xl" data-animation-in="fadeIn" data-animation-out="fadeOut">
              <a id="nav-link-demos" class="nav-link g-py-7 g-px-0" href="#" aria-haspopup="true" aria-expanded="false" aria-controls="My account">My account</a>

              <ul class="hs-sub-menu list-unstyled u-shadow-v11 g-brd-top g-brd-primary g-brd-top-2 g-min-width-220 g-mt-18 g-mt-8--lg--scrolling">
                <!-- Admin -->
                <!--<li class="dropdown-item hs-has-sub-menu ">
                  <a id="nav-link&#45;&#45;demos&#45;&#45;one-page" class="nav-link" href="#" aria-haspopup="true" aria-expanded="false" aria-controls="Admin menu">Admin</a>

                  <ul class="hs-sub-menu list-unstyled u-shadow-v11 u-dropdown-col-1 g-brd-top g-brd-primary g-brd-top-2 g-min-width-220 g-mt-minus-2 hs-reversed"  aria-labelledby="Admin items">
                    <li class="dropdown-item ">
                      <a class="nav-link">Profile</a>
                    </li>
                    <li class="dropdown-item ">
                      <a class="nav-link">Products</a>
                    </li>
                  </ul>
                </li>-->

                <li class="dropdown-item " *ngIf="accountService.hasAnyAuthority('PRODUCER')">
                  <a class="nav-link" href="https://administration.madeby.earth" target="_blank">Admin</a>
                </li>
                <!-- Admin -->

                <!-- Other account items -->
                <!--<li class="dropdown-item ">
                  <a class="nav-link">Change password</a>
                </li>-->

                <li class="dropdown-item" (click)="logout()">
                  <a class="nav-link"  href="#">Logout</a>
                </li>
                <!-- End Other account items -->
              </ul>
            </li>
            <!-- End Demos -->
          </ul>
        </div>
        <!-- End Navigation -->
      </div>
    </nav>
  </div>
</header>
<!-- End Header -->
