import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import {Login} from '../login/login.model';
import {environment} from '../../../environments/environment';

type JwtToken = {
  id_token: string;
};

type ResponseToken = {
  access_token: string;
  token_type: string;
  refresh_token: string;
  expires_in: number;
  scope: string;
};

@Injectable({ providedIn: 'root' })
export class AuthServerProvider {
  constructor(private http: HttpClient, private $localStorage: LocalStorageService, private $sessionStorage: SessionStorageService) {}

  getToken(): string {
    return this.$localStorage.retrieve('authenticationToken') || this.$sessionStorage.retrieve('authenticationToken') || '';
  }

  login(credentials: Login): Observable<void> {
    const headers = new HttpHeaders(
      {
        Authorization: 'Basic YXBwLWNsaWVudDo3MTgzMjhmOS1kMWIwLTQ3NDQtOTJmNS05YTcyYjQ2N2RjOTY=',
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    );
    const formData = new HttpParams()
      .set('username', credentials.username)
      .set('password', credentials.password)
      .set('grant_type', 'password');

    return this.http.post<ResponseToken>(environment.basePath + '/oauth/token', formData, {headers})
      .pipe(map(response => this.authenticateSuccess(response, credentials.rememberMe)));
  }

  logout(): Observable<void> {
    return new Observable(observer => {
      this.$localStorage.clear('authenticationToken');
      this.$sessionStorage.clear('authenticationToken');
      observer.complete();
    });
  }

  private authenticateSuccess(response: ResponseToken, rememberMe: boolean): void {
    const jwt = response.access_token;
    if (rememberMe) {
      this.$localStorage.store('authenticationToken', jwt);
    } else {
      this.$sessionStorage.store('authenticationToken', jwt);
    }
  }
}
