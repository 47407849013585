/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Document { 
    description?: string;
    filename: string;
    id?: number;
    mimeType: string;
    name: string;
    status: Document.StatusEnum;
}
export namespace Document {
    export type StatusEnum = 'Archived' | 'Deleted' | 'Draft' | 'Hidden' | 'Published';
    export const StatusEnum = {
        Archived: 'Archived' as StatusEnum,
        Deleted: 'Deleted' as StatusEnum,
        Draft: 'Draft' as StatusEnum,
        Hidden: 'Hidden' as StatusEnum,
        Published: 'Published' as StatusEnum
    };
}


