import {Injectable, OnDestroy} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {environment} from '../../environments/environment';
import {SubSink} from 'subsink';
declare var ga;

// npm install --save-dev @types/google.analytics

@Injectable()
export class GoogleAnalyticsService implements OnDestroy{
  private subs = new SubSink();

  constructor(public router: Router) {
    if (environment.analyticsEnabled) {
      this.subs.sink = this.router.events.subscribe(event => {
        try {
          if (typeof ga === 'function') {
            if (event instanceof NavigationEnd) {
              ga('set', 'page', event.urlAfterRedirects);
              ga('send', 'pageview');
              // console.log('%%% Google Analytics page view event %%%');
            }
          }
        } catch (e) {
          // console.log(e);
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  /**
   * Emit google analytics event
   * Fire event example:
   * this.emitEvent("testCategory", "testAction", "testLabel", 10);
   * @param {string} eventCategory
   * @param {string} eventAction
   * @param {string} eventLabel
   * @param {number} eventValue
   */
  public emitEvent(eventCategory: string,
                   eventAction: string,
                   eventLabel: string = null,
                   eventValue: number = null) {
    if (environment.analyticsEnabled) {
      if (typeof ga === 'function') {
        ga('send', 'event', {
          eventCategory: eventCategory,
          eventLabel: eventLabel,
          eventAction: eventAction,
          eventValue: eventValue
        });
      }
    }
  }


}
