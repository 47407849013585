/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AddEditProductImageReqBody { 
    description?: string;
    name: string;
    ordering?: number;
    productId: number;
    uploader: AddEditProductImageReqBody.UploaderEnum;
}
export namespace AddEditProductImageReqBody {
    export type UploaderEnum = 'Artisan' | 'Brand';
    export const UploaderEnum = {
        Artisan: 'Artisan' as UploaderEnum,
        Brand: 'Brand' as UploaderEnum
    };
}


