import { Injectable } from '@angular/core';

import {Title} from '@angular/platform-browser';
import {AppConstants} from '../../app.constants';

declare var jQuery;

@Injectable({
  providedIn: 'root'
})
export class MetadataService {

  constructor(private title: Title) {
  }

  public setMetadata(title: string, customDescription: string = null, url: string = null, image: string = null): void {
    // console.log('setMetadata: ' + title);

    const newTitle = (title ? title + ' - ' : '') + AppConstants.FixedTitle;
    const newDescription = ((customDescription == null || customDescription.trim().length === 0) ? '' : customDescription + ' - ') + AppConstants.DefaultDescription;
    const newUrl = AppConstants.DefaultUrl + ((url == null) ? '' : url);

    if (!image) {
      image = AppConstants.DefaultImage;
    }

    this.title.setTitle(newTitle);
    jQuery('meta[name=description]').attr('content', newDescription);
    // jQuery('meta[name=apple-itunes-app]').attr("content", "app-id=, app-argument=" + url);
    jQuery('meta[property="og:title"]').attr('content', newTitle);
    jQuery('meta[property="og:image"]').attr('content', image);
    jQuery('meta[property="og:url"]').attr('content', newUrl);
    jQuery('meta[property="og:description"]').attr('content', newDescription);
    jQuery('meta[name="twitter:title"]').attr('content', newTitle);
    jQuery('meta[name="twitter:description"]').attr('content', newDescription);
    jQuery('meta[name="twitter:image"]').attr('content', image);
  }
}
