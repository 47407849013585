/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Producer } from './producer';
import { Document } from './document';
import { Certification } from './certification';


export interface ProducerCertification { 
    certification?: Certification;
    document?: Document;
    expiryDate?: string;
    id?: number;
    idNumber?: string;
    producer?: Producer;
    status?: ProducerCertification.StatusEnum;
}
export namespace ProducerCertification {
    export type StatusEnum = 'NotVerified' | 'Verified';
    export const StatusEnum = {
        NotVerified: 'NotVerified' as StatusEnum,
        Verified: 'Verified' as StatusEnum
    };
}


