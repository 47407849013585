import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { FourOFourComponent } from './four-ofour/four-ofour.component';
import {ApiModule, Configuration} from './api';
import {environment} from '../environments/environment';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {TheseHandsWebsiteCoreModule} from './core/core.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {GoogleAnalyticsService} from './analytics/google-analytics.service';
import {DEFAULT_TIMEOUT, TimeoutInterceptor} from './core/interceptor/timeout.interceptor';

export function apiConfig(): Configuration {
  return new Configuration({
    basePath: environment.basePath
  });
}

@NgModule({
  declarations: [
    AppComponent,
    FourOFourComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TheseHandsWebsiteCoreModule,
    AppRoutingModule,
    SharedModule,
    ApiModule.forRoot(apiConfig),
  ],
  providers: [
    GoogleAnalyticsService,
    [{ provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true }],
    [{ provide: DEFAULT_TIMEOUT, useValue: 30000 }]
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
