/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Country } from './country';
import { Image } from './image';


export interface ProductLocation { 
    country?: Country;
    date?: string;
    description?: string;
    id?: number;
    latitude?: number;
    longitude?: number;
    materialsUsed?: string;
    name?: string;
    ordering?: number;
    profileImage?: Image;
    status?: ProductLocation.StatusEnum;
}
export namespace ProductLocation {
    export type StatusEnum = 'NotVerified' | 'Verified';
    export const StatusEnum = {
        NotVerified: 'NotVerified' as StatusEnum,
        Verified: 'Verified' as StatusEnum
    };
}


