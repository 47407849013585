<ng-container *ngIf="globalService.showScaffolding | async">
  <div class="header-margin">
    <app-header></app-header>
  </div>
</ng-container>

<router-outlet></router-outlet>

<ng-container *ngIf="globalService.showScaffolding | async">
  <app-footer></app-footer>
</ng-container>

<app-arrow-top></app-arrow-top>
